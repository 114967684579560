import type { SearchOptions } from '~/lib/AlgoliaService';
import type { TagOverviewApiResponseModel } from '~/server/api/algolia/tag-overview.get';

export async function useAlgoliaContentfulSearch(query: SearchOptions) {
  const { data } = await useFetch<TagOverviewApiResponseModel>(
    '/api/algolia/tag-overview',
    {
      ...DEFAULT_FETCH_OPTIONS,
      query
    }
  );

  return data.value as TagOverviewApiResponseModel;
}
